<template>
  <div class="container">
    <div class="content">
      <div class="homeBtn" @click="goHome">Manage My Project</div>
      <div class="btn" @click="apply">Edit</div>
      <div class="item">

        <div style="width: 209px; height: 209px;position: relative;">
          <img v-if="!application.entry_info.cover_pic && application.program==='MR'" style="width: 100%; height: 100%;" src="../../assets/pic.png" />
          <img v-if="!application.entry_info.cover_pic && application.program==='PIE'" style="width: 100%; height: 100%;" src="../../assets/piePic.png" />
          <img v-if="!application.entry_info.cover_pic && application.program==='POE'" style="width: 100%; height: 100%;" src="../../assets/poePic.png" />
          <img v-if="application.entry_info.cover_pic" style="width: 100%; height: 100%;" :src="application.entry_info.cover_pic" />
          <!-- <div
            style="padding: 8px 12px; border-radius: 0 15px 0 15px; position: absolute; top: 0; right: -1px; background: #F65D4D;"
            v-if="application.application_status === '已缴费'"
          >
            {{ 'Paid' }}
          </div>
          <div
            style="padding: 8px 12px; border-radius: 0 15px 0 15px; position: absolute; top: 0; right: -1px; background: #FFB414;"
            v-else
          >
            {{ 'Pending payment' }}
          </div> -->
        </div>

        <!--<img
          v-if="!application.entry_info.cover_pic"
          src="../../assets/pic.png"
        />
        <img
          v-if="application.entry_info.cover_pic"
          :src="application.entry_info.cover_pic"
        />-->

        <div class="main">
          <div class="name">{{ application.entry_info.name }}</div>
          <div class="id">
            <!-- ID:{{ application.entry_info.entry_code }} Password:{{
              application.entry_info.join_pwd
            }} -->
            ID: {{ application.entry_info.entry_code }} <span style="margin-left: 10px;color:#0E4890">{{application.account_info.account_name}}</span>
          </div>
          <div class="id">
          <!-- <span style="border: 1px solid #999999;color: #999999; font-size: 20px;border-radius: 17px; height:34px;line-height: 34px; text-align: center; padding: 0 12px;">
            ID:{{ application.entry_info.entry_code }}
          </span> -->
            <span v-if="application.entry_info.category" style="margin-right: 20px;border: 1px solid #17396D; font-size: 20px;border-radius: 17px; height:34px;line-height: 34px; text-align: center;color: #00255E;padding: 0 12px;">
            {{ application.entry_info.category }}
          </span>
            <span
              style="border: 1px solid #17396D; font-size: 20px;border-radius: 17px;height:34px;line-height: 34px; text-align: center; color: #00255E;padding: 0 12px;">
            {{ application.application_project }}
          </span>
          </div>
          <div class="intro" :title="application.entry_info.intro">
            {{ application.entry_info.intro }}
          </div>
        </div>
      </div>
      <div class="title">My Registration Information</div>
      <div class="infoItem">
        Name: {{
          application.student_info.student_lastName +
          application.student_info.student_givenName
        }}
      </div>
      <div class="infoItem">
        School: {{ application.account_info.account_name }}
      </div>
      <div class="infoItem">
        Email: {{ application.student_info.student_email }}
      </div>
      <div class="infoItem">
        Status: {{
          application.application_status === '已缴费'
            ? 'Paid'
            :  (application.application_status === '已取消' ? 'Cancel' : 'Pending payment')
        }}
        <span class="paymentBtn" v-if="application.application_status === '已缴费'" @click="toPayment">View Receipt</span>
        <span class="paymentBtn" v-if="application.application_status === '待缴费'" @click="toPay">
        Pay Now
         <!-- <PayPal env="sandbox" :amount="0.01" :currency="orderData.currency_code" :client="credentials"
                    :button-style="buttonStyle" @payment-authorized="paymentAuthorized"
                    @payment-completed="paymentCompleted" @payment-cancelled="paymentCancelled">
                  </PayPal> -->

                </span>
      </div>

      <div class="title">
        <div>Creator List</div>
        <div class="pwdBtn" @click="showPwd = true">Show the joining password</div>
      </div>
      <el-empty
        v-if="!teammates.length"
        description="暂无成员"
        :image-size="160"
      ></el-empty>
      <div class="teammate" v-for="(item, index) in teammates" :key="index">
        <div class="name">
          {{
            item.student_info.student_lastName +
            item.student_info.student_givenName
          }}
        </div>
        <div class="school">{{ item.account_info.account_name }}</div>
        <div class="email">{{ item.student_info.student_email }}</div>
      </div>

    </div>
    <Receipt ref="receipt"></Receipt>
  </div>
</template>

<script>

// paypal 测试账号  sb-vvzgb17842176@personal.example.com  密码 7F=Ub81o

import { getApplicationByID, getEntryApplications } from '../../api/index'
import Receipt from '../payment.vue'
import '../../assets/common/font.css'
export default {
  components: {
    Receipt
  },
  data () {
    return {
      id: this.$route.query.id,
      application: {
        entry_info: { _id: {} },
        student_info: {},
        account_info: {}
      },
      teammates: [],
      showPwd: false
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      getApplicationByID(this.id).then((res) => {
        if (res.data.code === 0) {
          this.application = res.data.data

          getEntryApplications(this.application.entry_info._id.$id).then((res) => {
            if (res.data.code === 0) {
              this.teammates = res.data.data
            }
          })
        }
      })
    },
    goHome (index) {
      if (this.application.application_status !== '已缴费') {
        this.$message.error("You haven't paid yet")
        return
      }
      // mr 模版id  631169b9a4640b60318b4602
      // poe 模版id  63116846a4640b19318b4567
      // pie 模版id  63116951a4640b60318b4567

      // let pathTemplate_id = '631169b9a4640b60318b4602'

      // if (this.application.program==='PIE'){
      //   pathTemplate_id = '63116846a4640b19318b4567'

      // }else if (this.application.program==='POE'){
      //   pathTemplate_id = '63116951a4640b60318b4567'

      // }

      // if (this.from == 'student') {
      const routeData = this.$router.resolve({ path: 'pghome', query: { template_id: this.application.template_id, project_id: this.application.project_id, application_team_code: this.application.entry_info.entry_code, accountid: this.application.entry_info.account_id } })
      // } else {
      //   routeData= this.$router.resolve({ path: 'Home', query: { template_id: this.project_template_id,project_id: this.educator_project_id,application_team_code:this.tableData2[index].code, accountid: this.tableData2[index].id }})
      // }
      window.open(routeData.href, '_blank')
    },

    apply () {
      this.$router.push({
        path: '/edit',
        query: {
          id: this.application.entry_info._id.$id,
          program: this.application.program
        }
      })
    },
    toPayment () {
      this.$refs.receipt.open(this.application._id.$id)
    },
    toPay () {
      this.$router.push({
        path: '/pay',
        query: {
          id: this.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  min-height: 900px;
  background: url('../../assets/applyBack.png');
  background-size: 110% 120%;
  background-position: 10% 10%;
  font-family: "DDINAlternateRegular";
  .paymentBtn{
    padding: 5px 10px;
    background: #FFB414;
    color: #fff;
    font-size: 18px;
    border-radius: 20px;
    cursor: pointer;
    margin-left: 14px;
    font-family: "DDINAlternateRegular";
  }

  .content {
    width: 66%;
    margin: 0 auto;
    box-sizing: border-box;
    height: 100%;
    padding: 60px 70px;
    background: #fff;
    position: relative;
    .btn {
      background: #ffffff;
      width: 120px;
      height: 34px;
      border-radius: 20px;
      border: 2px solid #0e4890;
      line-height: 34px;
      text-align: center;
      color: #0e4890;
      position: absolute;
      top: 40px;
      right: 40px;
      cursor: pointer;
    }
    .homeBtn{
      background: #0e4890;
      width: 186px;
      height: 34px;
      border-radius: 20px;
      line-height: 34px;
      text-align: center;
      color: #fff;
      position: absolute;
      top: 40px;
      right: 180px;
      cursor: pointer;
    }
    .pwdBtn{
      width: 266px;
      height: 34px;
      border-radius: 20px;
      line-height: 34px;
      text-align: center;
      color: #0e4890;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
    }
    .item {
      display: flex;
      /*height: 200px;*/
      width: 100%;
      margin: 5% auto 30px auto;
      position: relative;
      img {
        display: block;
        height: 100%;
        border: 1px dotted #0e4890;
        border-radius: 15px;
      }
      .main {
        padding: 4px;
        margin-left: 16px;
        height: 100%;
        width: calc(100% - 205px);
        .name {
          color: #333333;
          font-weight: bold;
          font-size: 36px;
        }
        .id {
          margin-top: 10px;
          color: #949494;
        }
        .tags {
          margin-top: 10px;
        }
        .tag {
          border: 1.4px solid #17396d;
          color: #17396d;
          padding: 0 8px;
          border-radius: 12px;
          margin-right: 8px;
          font-size: 14px;
        }
        .intro {
          margin-top: 16px;
          color: #999999;
          width: 100%;
          overflow: hidden;
          // height: 90px;
          font-weight: 400;
          font-size: 24px;
          text-overflow: ellipsis;//超出文本设置为...
          display:-webkit-box;//将div1转换为盒子模型
          -webkit-line-clamp: 3;
          overflow: hidden;//超出隐藏
          -webkit-box-orient: vertical;//从顶部向底部垂直布置子元素
        }
      }
    }
    .title {
      width: 100%;
      margin: 4% auto 0 auto;
      font-weight: bold;
      font-size: 30px;
      font-family: "DDINAlternateRegular";
      font-weight: bold;
      color: #0E4890;
      display: flex;
      justify-content: space-between;
    }
    .infoItem {
      width: 100%;
      box-sizing: border-box;
      margin: 20px auto;
      font-weight: bold;
      padding: 0 10px;
      border-left: 4px solid #ffb414;
      font-size: 22px;
      font-family: "DDINAlternateRegular";
      font-weight: 400;
      color: #A4A4A4;
      display: flex;
      align-items: center;
    }
  }
}
.teammate {
  width: 100%;
  margin: 10px auto;
  color: #a4a4a4;
  border-bottom: 1px solid #707070;
  display: flex;
  height: 40px;
  line-height: 40px;
  font-family: "DDINAlternateRegular";
  .name {
    width: 30%;
  }
  .school {
    width: 40%;
  }
  .email {
    width: 30%;
  }
}
.diaTitle{
    text-align: center;
    color: #F65D4D;
    font-size: 30px;
    font-family: "DDINAlternateRegular";
    font-weight: bold;
    text-decoration:underline
  }
  .tips{
    color: #333333;
    font-family: "DDINAlternateRegular";
    font-weight: bold;
    width: 360px;
    margin: 40px auto;
    font-size: 20px;
    line-height: 24px;
  }
  .foot{
    display: flex;
    justify-content: center;
    margin-bottom:20px;
    .cancel{
      background: #0E4890;
      border-radius: 25px;
      padding: 10px 70px;
      color: #fff;
      font-family: "DDINAlternateRegular";
      cursor: pointer;
      font-size: 18px;
    }
    .confirm{
      background: #FFB414;
      border-radius: 25px;
      padding: 10px 70px;
      color: #fff;
      font-family: "DDINAlternateRegular";
      cursor: pointer;
      margin-left: 40px;
      font-size: 18px;
    }
  }
  ::v-deep .el-dialog{
    border-radius: 15px!important;
  }
  ::v-deep .el-dialog__headerbtn{
    display: none;
  }
</style>
